<template>
  <div class="d-flex flex-row flex-wrap login">
    <div class="d-flex justify-center align-center login__form-wrapper">
      <div class="text-center welcome">
        <img src="@/assets/images/logo-login.png" alt="login">
        <div class="welcome__title">Restablece tu Contraseña</div><br>
        <div class="welcome__description mb-6">
          Te enviaremos un correo con las instrucciones para crear una nueva contraseña
        </div>

        <validation-observer ref="form">
          <form novalidate autocomplete="off" @submit.prevent="passwordReset">
            <validation-provider
              v-slot="{ errors }"
              name="Correo electrónico"
              rules="required|email"
            >
              <v-text-field
                vid="email"
                v-model="model.email"
                type="email"
                outlined
                dense
                :error-messages="errors"
                label="Correo electrónico"
                required
              ></v-text-field>
            </validation-provider>
            <v-btn block class="elevation-0 text-none" color="primary" type="submit">
              Restablecer Contraseña
            </v-btn>
          </form>
        </validation-observer>
      </div>
    </div>
    <div class="login__banner">
    </div>
  </div>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import notificationMixin from '@/mixins/notificationMixin'
import loaderMixin from '@/mixins/loaderMixin'
import { PASSWORD_RESET_URL } from '@/constants/ServicesConstants'

export default {
  name: 'PasswordReset',
  data: () => ({
    model: {
      email: ''
    }
  }),
  mixins: [
    crudServiceMixin,
    notificationMixin,
    loaderMixin
  ],
  methods: {
    passwordReset () {
      this.$refs.form.validate()
        .then((valid) => {
          if (valid) {
            this.model.fairSlug = this.$route.params.slug
            this.showLoader()
            this.post(PASSWORD_RESET_URL, this.model)
              .then(() => {
                this.showSuccess('Se le ha enviado un enlace de recuperación a su correo electrónico.', 5000)
                this.hideLoader()
              }).catch((e) => {
                this.hideLoader()
                this.showError(e.response.data.error || 'Ha ocurrido un error.', 5000)
              })
          }
        })
    }
  }
}
</script>
<style lang="scss">
.login {
  height: 100vh;

  &__form-wrapper {
    width: 100%;
  }

  &__banner {
    background: url("../../../assets/images/login-expositor-banner.jpg") no-repeat center;
    background-size: cover;
    display: none;
    width: 55%;
  }
}

@media (min-width: 990px) {
  .login {
    &__form-wrapper {
      width: 45%;
    }

    &__banner {
      display: flex;
    }
  }
}

.welcome {
  width: 350px;

  img {
    width: 320px;
  }

  &__title {
    font-size: 30px;
    font-family: 'Roboto Bold', sans-serif !important;
  }

  &__description {
    color: #808080;
    line-height: 10px
  }

}

</style>
